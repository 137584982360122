

nav{
  background-color: #482aff;
  height: 80px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  box-shadow: 0 5px 5px rgba(5,25,114,.4);
}

nav div {
  width: 90%;
  margin: auto;
}

nav ul {
  display: flex;
  align-items: center;
  margin: 0px;
  padding-left: 0px;
}

nav ul li {
  margin: 0px 5px;
  list-style: none;
}

nav ul li a{
  color: black;
  text-decoration: none;
}

nav ul li:nth-of-type(1) a{
  font-weight: 500;
  line-height: 24px;
  padding: 15px;
  color: white;
}   

nav ul li:nth-of-type(2){
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 5px 25px -3px #3867d6;
  padding: 10px 20px;
}

nav ul li:nth-of-type(2) a{
  color: #482aff;
  font-size: 20px;
  font-weight: 600;
}


@media(max-width:567px){
  nav ul li:nth-of-type(1) a{
    font-weight: 500;
    line-height: 20px;
    padding: 12px;
    color: white;
  }   
  
  nav ul li:nth-of-type(2){
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 5px 25px -3px #3867d6;
    padding: 10px 15px;
  }
  
  nav ul li:nth-of-type(2) a{
    color: #197dbf;
    font-size: 15px;
    font-weight: 600;
  }
}


/* section one  */

.SectionOne {
  background-color: #482aff;
  padding:60px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.SectionOne 
.text-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.SectionOne 
.text-box h4{
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;  
  color: #fff;
}

.SectionOne
.text-box p:nth-of-type(1){
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
}

.SectionOne
.text-box a{
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 5px 25px -3px #3867d6;
  color: #482aff;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 600;
}

.SectionOne
.img-box img{
  width: 100%;
  height: auto;
  border-radius: 20px;
}

@media(max-width:991px){
  .SectionOne 
  .text-box h4{
    font-size: 1.2rem;  
  }

  .SectionOne
  .text-box p:nth-of-type(1){
    font-size: 18px;
  }


  .SectionOne
  .text-box a{
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 5px 25px -3px #3867d6;
    color: #3867d6;
    cursor: pointer;
    padding: 7px 15px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
  }

}

@media(max-width:768px){
  .SectionOne 
  .text-box{
    margin-bottom: 50px;
  }
}

/* section two */

.SectionTwo
.container{
  position: relative;
  top: -30px;
}

.SectionTwo
.head_text{
  text-align: center;
  background-color: #482aff;
  padding: 60px 0px;
}

.SectionTwo
.head_text h4{
  color: white;
}

.SectionTwo 
.col-md-4{
  box-shadow: 0 0 50px -4px #c2cfd6;
  background-color: #fff;
  padding: 10px 20px;
}

.SectionTwo
.row > div:nth-child(1) {
  position: relative;
  left: 10px;
  z-index: 1;
}

.SectionTwo
.row > div:nth-child(2) {
  position: relative;
  left: 5px;
  z-index: 2;
}

.SectionTwo
.row > div:nth-child(3) {
  z-index: 3;
}

.SectionTwo
.text-img {
  text-align: center;
  height: 100%;
}

.SectionTwo
.img-box{
  text-align: center;
}

.SectionTwo
.img-box p{
  margin: 10px auto;
  font-size: 20px;
  font-weight: 600;
}

.SectionTwo
.img-box div {
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.SectionTwo
.teacher{
  background-image: url('/src/assets/img//img1.jpg');
 
}
.SectionTwo
.ceo{
  background-image: url('/src/assets/img//img2.jpg');
}
.SectionTwo
.student{
  background-image: url('/src/assets/img//img3.jpg');
}

@media(max-width:1199px){
  .SectionTwo
  .img-box div{
    height: 160px;
  }
}


@media(max-width:768px){
  .SectionTwo
  .img-box div {
    height: 100px;
  }
  .SectionTwo
  .img-box p{
    margin: 5px auto;
    font-size: 15px;
  }
  .SectionTwo 
  .col-md-4{
    padding: 5px 15px;
  }
  .SectionTwo
  .img-box div{
    background-size: cover;
  }
}

@media(max-width:567px){
  .SectionTwo .row > div:nth-child(1){
    left: 5px;
  }
  .SectionTwo .row > div:nth-child(2){
    left: 0px;
  }
  .SectionTwo .row > div:nth-child(3){
    position: relative;
    left: -5px;
  }
  .SectionTwo .head_text{
    padding: 40px 0px 60px 0px;
  }
}

/* section three */

.SectionThree{
  margin-bottom: 40px;
}

.SectionThree
.head_text{
  text-align: center;
}

.SectionThree
.head_text h4{
  margin: 50px auto 20px auto;
  color: #482aff;
  font-size: 20px;
  font-weight: 800;
}

  .SectionThree 
  .col-md-4{
    margin: 10px auto;
  }

.SectionThree 
.text-box{
  padding: 15px;
  background-color: #fff;
  border-left: 5px solid #482aff;
  box-shadow: 0 0 20px -4px #c2cfd6;
  height: 100%;
}

.SectionThree 
.text-box i{
  color:#482aff;
}

.SectionThree
.text-box p{
  color: #29363d;
  font-size: 22px;
  margin: 10px 0;
  font-weight: 700;
}

/* section four */

.SectionFour{
  background-color: #482aff ;
  margin-bottom: 20px auto;
}

.SectionFour 
.text-box{
  display: flex;
}

.SectionFour 
.text-box > span{
  font-size: 45px;
  padding: 5px;
  color: #fff;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.SectionFour
.text-box div{
  margin: 10px auto;
}

.SectionFour
.text-box div i{
  font-size: 20px;
  color: #fff;
}

  .SectionFour
  .text-box div p{
  color: #fff;
}

@media (max-width:567px) {
  .SectionFour
  .text-box div{
    margin:  auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end ; 
    width: 100%;
  }
  
}

.Footer{
  padding: 30px;
}

.Footer .box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer .box h5{
  font-size: 30px;
  font-weight: 700;
  margin: 15px;
}



.Footer .box h5 {
  font-family: 'Audiowide', cursive;
  font-size: 30px;
  margin: 20px;
  
}

.Footer .box div i{
   background-color: #482aff;
    border-radius: 8px;
    box-shadow: 0 5px 25px -3px #197dbf;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    margin: 10px;
    padding: 12px;
}

/* request demo */

:root {
  --text-gray-900: #111827;
  --text-gray-700: #253a4e;
  --text-gray-500: #6b7280;
  --bg-indigo-50: #f6f6ff;
  --bg-blue-500: #536dfe;
  --bg-indigo-50: #f6f6ff;
  --border-gray-300: #d1d5db;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif; */
}

body, html {
  width: 100%;
  height: 100%;
}

p, h1, h2, h3, h4, h5, h5 {
  margin: 0;
  padding: 0;
}

input, textarea, button, select {
  border: none;
  outline: none;
  background-color: transparent;
}

a {
  text-decoration: none;
}

.demo-form-section {
  background-color: var(--bg-indigo-50);
  height: 100%;
  padding: 120px 0px 50px 0px;
}

@media(max-width:500px){
  .demo-form-section{
    padding: 50px 0px 50px 0px;
  }
}

.demo-form-section .demo-form-con {
  background-color: #fff;
  margin: 0 auto;
  border-radius: 0.75rem;
  padding: 40px 6rem;
  max-width: 768px;
}
.demo-form-section .form-title h2 {
  font-weight: 600;
  color: var(--text-gray-700);
  margin-bottom: 30px;
}

.demo-form-section .form-description p:nth-child(1),
.demo-form-section .form-description p:nth-child(2),
.demo-form-section .form-description p:nth-child(3) {
  font-size: 14px;
  margin-bottom: 25px;
}

.demo-form-section .form-description p:nth-child(1) {
  font-weight: 500;
}

.demo-form-section .form-description p:nth-child(2),
.demo-form-section .form-description p:nth-child(3) {
  color: var(--text-gray-500);
}

.demo-form-section .horizontal-divider {
  width: 100%;
  height: 0.3px;
  background-color: rgba(143, 151, 167, 0.673);
  margin-bottom: 25px;
  border-radius: 10px;
}

.demo-form-section form {
  width: 100%;
}

.demo-form-section .form-section {
  margin-bottom: 25px;
}

.demo-form-section .form-section h4 {
  color: var(--text-gray-900);
  margin-bottom: 25px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.demo-form-section .form-section h4 span {
  color: var(--text-gray-500);
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
}

.demo-form-section .form-section .choices-con {
  display: flex;
  flex-wrap: wrap;
} 


.demo-form-section .form-section .choices-con .choice-btn {
  border-radius: 1rem;
  border: 1px solid var(--border-gray-300);
  background-color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  color: var(--text-gray-700);
  margin-right: 20px;
  margin-bottom: 20px;
  transition: .3s;
  cursor: pointer;
}

.demo-form-section .form-section .choices-con .choice-btn:hover {
  color: var(--bg-blue-500);
  background-color: var(--bg-indigo-50);
  border-color: var(--bg-indigo-50);
}

.demo-form-section .form-section .choices-con .choice-btn.active {
  background-color: var(--bg-blue-500);
  color: #fff;
}

.demo-form-section .form-section .input-boxes-con .input-box {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.demo-form-section .form-section .input-boxes-con .input-box .input-with-checkbox {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.demo-form-section .form-section .input-boxes-con .input-box label {
  font-size: 13px;
  font-weight: 600;
  color: var(--text-gray-700);
}

.demo-form-section .form-section .input-boxes-con .input-box input[type=text],
.demo-form-section .form-section .input-boxes-con .input-box input[type=number],
.demo-form-section .form-section .input-boxes-con .input-box input[type=email],
.demo-form-section .form-section .input-boxes-con .input-box select {
  font-size: 13px;
  border: 1px solid var(--border-gray-300);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  width: 60%;
}

.demo-form-section .form-section .input-boxes-con .input-box input[type=text]:focus,
.demo-form-section .form-section .input-boxes-con .input-box input[type=tel]:focus,
.demo-form-section .form-section .input-boxes-con .input-box input[type=email]:focus,
.demo-form-section .form-section .input-boxes-con .input-box select:focus {
  outline: 2px solid #6366f1;
}

.demo-form-section .form-section .input-boxes-con .input-box .input-with-checkbox input[type=text],
.demo-form-section .form-section .input-boxes-con .input-box .input-with-checkbox input[type=tel],
.demo-form-section .form-section .input-boxes-con .input-box  .input-with-checkbox input[type=email] {
  width: 100%;
}

.demo-form-section .form-section .input-boxes-con .checkbox-con {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.demo-form-section .form-section .input-boxes-con .checkbox-con label {
  color: var(--text-gray-500);
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
  -webkit-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

.demo-form-section .form-section .input-boxes-con .input-box select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}

.demo-form-section .form-section p {
  color: var(--text-gray-500);
  font-size: 14px;
}

.demo-form-section .submit-btn {
  background-color: var(--bg-blue-500);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 0.75rem 0;
  border-radius: 0.75rem;
  width: 100%;
  transition: .3s;
  cursor: pointer;
}

.demo-form-section .submit-btn:disabled {
  background-color: #482aff;
  pointer-events: none;
}

.demo-form-section .submit-btn:hover {
  background-color: #2c4cff;
}

.err-message {
  color: red;
  font-size: 13px;
}

@media only screen and (max-width: 768px) {
  .demo-form-section {
     
  }
  .demo-form-section .demo-form-con {
      max-width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .demo-form-section {
     
  }
  .demo-form-section .demo-form-con {
      padding: 40px 20px;
      max-width: 90%;
  }

  .demo-form-section .form-section .input-boxes-con .input-box {
      flex-direction: column;
      padding: 0;
      margin-bottom: 25px;
  }

  .demo-form-section .form-section .input-boxes-con .input-box label {
      margin-bottom: 6px;
  }

  .demo-form-section .form-section .input-boxes-con .input-box input[type=text], 
  .demo-form-section .form-section .input-boxes-con .input-box input[type=tel], 
  .demo-form-section .form-section .input-boxes-con .input-box input[type=email], 
  .demo-form-section .form-section .input-boxes-con .input-box select,
  .demo-form-section .form-section .input-boxes-con .input-box .input-with-checkbox {
      width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .demo-form-section .demo-form-con {
      max-width: 90%;
  }
}
